// import dependencies
import React from "react";
import { graphql } from "gatsby";

// import components
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Post from "../components/post";
import Section from "../components/section";

// import styles and assets
import * as styles from "./styles/blog-page.module.scss";

const BlogPage = ({ data, pageContext }) => {
    return (
        <Layout>
            <Section>
                <div className={styles.blogContainer}>
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                        <Post key={node.id} node={node} />
                    ))}
                    <Pagination pageContext={pageContext} />
                </div>
            </Section>
        </Layout>
    );
};
export default BlogPage;

export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 200, truncate: true, format: HTML)
                    frontmatter {
                        title
                        date
                        path
                        tags
                        featuredImage {
                            name
                            childImageSharp {
                                gatsbyImageData(
                                    width: 600
                                    height: 380
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
