// import dependencies
import React from "react";
import { Link } from "gatsby";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import * as styles from "./styles/pagination.module.scss";

export default function Pagination({ pageContext, color }) {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
        pageContext;

    return (
        <div className={styles.pagination}>
            {previousPagePath ? (
                <Link to={previousPagePath} className={styles.pagerButton}>
                    <p>
                        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                    </p>
                </Link>
            ) : null}
            {numberOfPages > 1 && (
                <div className={styles.pagerLocation}>
                    <p>
                        Page {humanPageNumber} of {numberOfPages}
                    </p>
                </div>
            )}
            {nextPagePath && (
                <Link to={nextPagePath} className={styles.pagerButton}>
                    <p>
                        <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                    </p>
                </Link>
            )}
        </div>
    );
}
